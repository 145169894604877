













































/* eslint-disable @typescript-eslint/camelcase */

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import InputTextarea from '@/components/Controls/InputTextarea.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    InputTextarea,
    Calendar
  }
})
export default class ResidentialTownhouse extends Vue {
  formData () {
    return {
      GeneralCondition: this.generalCondition,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      ApproximateBuildYear: this.approximateBuildYear,
      EstimatedValue: this.estimatedValue,
      CurrentRental: this.currentRental,
      NumberOfBedrooms: this.bedrooms,
      NumberOfBathrooms: this.bathrooms,
      CarParks: this.carParks,
      FloorArea: this.floorArea,
      Other: this.other,
      PropertyFeatures: this.propertyFeatures
    }
  }

  loadData (data: any) {
    this.generalCondition = data.GeneralCondition
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.approximateBuildYear = data.ApproximateBuildYear
    this.estimatedValue = data.EstimatedValue
    this.currentRental = data.CurrentRental
    this.bedrooms = data.NumberOfBedrooms
    this.bathrooms = data.NumberOfBathrooms
    this.carParks = data.CarParks
    this.floorArea = data.FloorArea
    this.other = data.Other
    this.propertyFeatures = data.PropertyFeatures
  }

  propertyType () {
    return 'Residential'
  }

  generalCondition = null
  floorConstruction = null
  wallConstruction = null
  roofConstruction = null
  approximateBuildYear = null
  estimatedValue = null
  currentRental = null
  bedrooms = null
  bathrooms = null
  carParks = null
  floorArea = null
  other = null
  propertyFeatures: string[] = []
}
